import React from "react"
import SEO from "../components/seo"
import { useIntl } from "react-intl"
import MainLayout from "../components/layouts/main"
import PageContent from "../components/page-content"
import { createResponsiveStyles } from "../helpers/theme"
import { translate, translateHtml } from "../helpers/grammar"

/**
 * Component rendering the "/contact" page of the site.
 *
 * @returns {JSX.Element}
 */
const ContactPage = () => {
  const intl = useIntl()

  // Translate text content for the page
  const pageTitle = translate(intl, "privacy.title")
  const pageContentTitle = translate(intl, "privacy.content.title")
  const pageContentText = translateHtml(intl, "privacy.content.text", {
    h2: {
      color: "text",
    },
    h3: {
      color: "text",
      fontWeight: "body",
    },
    h4: {
      fontFamily: "body",
      fontSize: createResponsiveStyles(2, 2, 2),
      color: "text",
      fontWeight: "dark",
    },
  })
  const pageContentBottomLink = {
    text: translate(intl, "privacy.content.bottomLink.text"),
    link: translate(intl, "privacy.content.bottomLink.link"),
  }

  // Render the component using "MainLayout"
  return (
    <MainLayout pageTitle={"privacy"} routeId={"privacy"}>
      <SEO title={pageTitle} />
      <PageContent
        title={pageContentTitle}
        text={pageContentText}
        bottomLink={pageContentBottomLink}
      />
    </MainLayout>
  )
}

export default ContactPage
