import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const pageContentContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  px: createResponsiveStyles(4, 7, 10),
  py: createResponsiveStyles(6, 10),
}

export const pageContentInnerStyles: SxStyleProp = {
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  pt: createResponsiveStyles(8, 6),
  width: createResponsiveStyles("100%", "100%", "80%"),
}

export const pageContentTitleStyles: SxStyleProp = {
  width: "100%",
  fontWeight: "bold",
  px: createResponsiveStyles("0", 3, 10),
}

export const pageContentTextStyles: SxStyleProp = {
  width: "100%",
  textAlign: "left",
  whiteSpace: "pre-line",
  pt: createResponsiveStyles(6, 7),
}
