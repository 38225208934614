/** @jsx jsx */
import {
  pageContentTextStyles,
  pageContentTitleStyles,
  pageContentInnerStyles,
  pageContentContainerStyles,
} from "./styles"
import { ReactNodeArray } from "react"
import { jsx, Flex, Styled, Box } from "theme-ui"

/**
 * Type definition for props required for "PageContent" component.
 *
 * @interface
 */
interface PageContentProps {
  title: string
  text: string | ReactNodeArray
  bottomLink: {
    text: string
    link: string
  }
}

/**
 * Component rendering the "PageContent" section of the site.
 *
 * 1. Heading (h1)
 * 2. Text
 * 3. Bottom Link
 *
 * Made for rendering text-only content.
 *
 * Used on "privacy" page.
 *
 * @param {PageContentProps} param
 *
 * @returns {JSX.Element}
 */
const PageContent = ({
  title,
  text,
  bottomLink,
}: PageContentProps): JSX.Element => {
  return (
    <Flex sx={pageContentContainerStyles}>
      <Flex sx={pageContentInnerStyles}>
        <Styled.h2 as={"h1"} sx={pageContentTitleStyles}>
          {title}
        </Styled.h2>
        <Box sx={pageContentTextStyles}>{text}</Box>
        <Styled.p sx={{ width: "100%", alignSelf: "flex-start" }}>
          <a sx={{ textAlign: "left" }} href={bottomLink.link}>
            {bottomLink.text}
          </a>
        </Styled.p>
      </Flex>
    </Flex>
  )
}

export default PageContent
